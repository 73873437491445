import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from "react";
import { ConfigContext } from "../ConfigContextProvider";
import { LocaleContext } from "../LocaleContextProvider";
import { FormattedMessage, injectIntl } from "react-intl";
import { useForm } from "react-hook-form";
import Modal from "react-modal";

import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const FormNewsletter = (props) => {
  const { closeModal, appId, email } = props;
  const { NEWSLETTER_URL, PROFESSION_URL, COUNTRY_URL } =
    useContext(ConfigContext);

  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const { locale } = useContext(LocaleContext);
  const [token, setToken] = useState("");

  const [countries, setCountries] = useState();
  const [professions, setProfessions] = useState();

  //register
  const [mode, setMode] = useState("LOGIN");

  const [statusChangePwd, setStatusChangePwd] = useState();

  const translateText = function (tstr) {
    return props.intl.formatMessage({
      id: tstr,
    });
    //document.form.entity.value = bstr;
  };

  const genericError = props.intl.formatMessage({
    id: "generic.form.error",
  });

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "all",
    reValidateMode: "all",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const passwordWatch = useRef({});
  passwordWatch.current = watch("pwd", "");

  const verifyRecaptchaCallback = React.useCallback((token) => {
    setToken(token);
  }, []);

  const onSubscribeNewsletter = useCallback((data) => {
    setErrorMessage();
    setSuccessMessage();
    setStatusChangePwd();
    const formData = new FormData();
    for (var i in data) {
      formData.append(i, data[i]);
    }
    fetch(NEWSLETTER_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      //body: formData,

      body: new URLSearchParams(formData).toString(),
    })
      .then((response) => response.text())
      .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
      .then((data) => {
        const ERRORE = data.getElementsByTagName("ERRORE");
        const DESERR = data.getElementsByTagName("DESERR");
        //const n = data.getElementsByTagName("result");

        if (ERRORE.length && DESERR.length) {
          setErrorMessage(DESERR[0].innerHTML);
        } else {
          setStatusChangePwd("SUCCESS");

        let objToSend={
            event: "newsletter_signup",
            form_newsletter: "true", 
            user_data: {
                email: formData.get('frm_email')
            }
        }

        window.sendToGTM && window.sendToGTM(objToSend)
        }
      })
      .catch((error) => {
        setErrorMessage(genericError);
      });
  });

  useEffect(() => {
    fetch(COUNTRY_URL)
      .then((res) => res.json())
      .then(
        (result) => {
          setCountries(result.countries);
        },
        (error) => {}
      );
    fetch(PROFESSION_URL + "_" + locale + ".json")
      .then((res) => res.json())
      .then(
        (result) => {
          setProfessions(result.professioni);
        },
        (error) => {}
      );
  }, []);

  const appIdLabel = (appId && appId.length) > 0 ? appId + "." : "";

  return (
    <div className="">
      {/*<div className="NewsletterModal__imgBlock">
        <img
          className="NewsletterModal__img"
          src="https://media21.flou.it/newsletter/newsletter.jpg"
        />
  </div>*/}

      <div className="">
        <div className="AuthenticationModal__title">
          <h1>
            <FormattedMessage id={"newsletter.form.title"} />
          </h1>
          <p>
            <FormattedMessage id={"newsletter.form.description"} />
          </p>
        </div>

        {statusChangePwd === "SUCCESS" ? (
          <>
            <div className="AuthenticationModal__message">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    successMessage ||
                    props.intl.formatMessage({
                      id: "newsletter.form.success",
                    }),
                }}
              />
            </div>
            <button
              className="AuthenticationModal__button"
              onClick={() => {
                setStatusChangePwd();
                setMode("LOGIN");
                closeModal();
              }}
            >
              Chiudi
            </button>
          </>
        ) : (
          <>
            {errorMessage && <p className="textInput__error">{errorMessage}</p>}

            <form onSubmit={handleSubmit(onSubscribeNewsletter)}>
              <input
                type="hidden"
                name="g-recaptcha-response"
                value={token}
                ref={register({ required: true })}
              />
              <input
                type="hidden"
                name="lang"
                defaultValue={locale}
                ref={register({ required: true })}
              />
              <input
                type="hidden"
                name="frm_lang"
                defaultValue={locale}
                ref={register({ required: true })}
              />
              <input
                type="hidden"
                name="appId"
                defaultValue={appId}
                ref={register()}
              />
              <input
                type="hidden"
                name="campaign"
                defaultValue={2}
                ref={register()}
              />
              <input
                type="hidden"
                name="wwwOrigine"
                defaultValue={0}
                ref={register()}
              />
              <input
                type="hidden"
                name="csurl"
                defaultValue={NEWSLETTER_URL}
                ref={register()}
              />

              <div className="AuthenticationModal__rowForm">
                {/* nome */}
                <div className="textInput__wrapper">
                  <input
                    className="textInput__input"
                    name="name"
                    placeholder={`${props.intl.formatMessage({
                      id: "placeholder.name",
                    })}*`}
                    ref={register({
                      required: props.intl.formatMessage({
                        id: "validation.required",
                      }),
                    })}
                  />
                  {errors.name && errors.name.message && (
                    <p className="textInput__error">{errors.name.message}</p>
                  )}
                </div>

                {/* cognome */}
                <div className="textInput__wrapper">
                  <input
                    className="textInput__input"
                    name="surname"
                    placeholder={`${props.intl.formatMessage({
                      id: "placeholder.surname",
                    })}*`}
                    ref={register({
                      required: props.intl.formatMessage({
                        id: "validation.required",
                      }),
                    })}
                  />
                  {errors.surame && errors.surame.message && (
                    <p className="textInput__error">{errors.surame.message}</p>
                  )}
                </div>
              </div>

              <div className="AuthenticationModal__rowForm">
                {/* email */}
                <div className="textInput__wrapper">
                  <input
                    className="textInput__input"
                    name="frm_email"
                    defaultValue={email || ""}
                    placeholder={`${props.intl.formatMessage({
                      id: "placeholder.email",
                    })}*`}
                    ref={register({
                      required: props.intl.formatMessage({
                        id: "validation.required",
                      }),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: props.intl.formatMessage({
                          id: "validation.email",
                        }),
                      },
                    })}
                  />
                  {errors.frm_email && errors.frm_email.message && (
                    <p className="textInput__error">
                      {errors.frm_email.message}
                    </p>
                  )}
                </div>

                {/* phone */}
                <div className="textInput__wrapper">
                  <input
                    className="textInput__input"
                    name="phone"
                    placeholder={props.intl.formatMessage({
                      id: "placeholder.phone",
                    })}
                    ref={register({})}
                  />
                  {errors.phone && errors.phone.message && (
                    <p className="textInput__error">{errors.phone.message}</p>
                  )}
                </div>
              </div>

              <div className="AuthenticationModal__rowForm">
                {/* country */}
                <div className="textInput__wrapper">
                  <select
                    name="country"
                    defaultValue=""
                    className="textInput__input"
                    ref={register({
                      required: props.intl.formatMessage({
                        id: "validation.required",
                      }),
                      pattern: {
                        value: /^[a-zA-Z]{2,}$/,
                        message: props.intl.formatMessage({
                          id: "validation.required",
                        }),
                      },
                    })}
                  >
                    <option key={"option"} value={""} disabled>
                    {`${props.intl.formatMessage({
                      id: "placeholder.select",
                    })}*`}
                    </option>
                    {countries &&
                      countries.map((item, i) => {
                        return (
                          <option key={"option" + i} value={item.id}>
                            {item.label}
                          </option>
                        );
                      })}
                  </select>
                  {errors.country && errors.country.message && (
                    <p className="textInput__error">{errors.country.message}</p>
                  )}
                </div>

                {/* city */}
                <div className="textInput__wrapper">
                  <input
                    className="textInput__input"
                    name="city"
                    placeholder={`${props.intl.formatMessage({
                      id: "placeholder.city",
                    })}*`}
                    ref={register({})}
                  />
                  {errors.city && errors.city.message && (
                    <p className="textInput__error">{errors.city.message}</p>
                  )}
                </div>
              </div>

              <div className="AuthenticationModal__rowForm">
                {/* prfessini */}
                <div className="textInput__wrapper">
                  <select
                    name="profession"
                    defaultValue=""
                    className="textInput__input"
                    ref={register({
                      required: props.intl.formatMessage({
                        id: "validation.required",
                      }),
                      pattern: {
                        value: /.+/,
                        message: props.intl.formatMessage({
                          id: "validation.required",
                        }),
                      },
                    })}
                  >
                    <option key={"option"} value={""} disabled>
                    {`${props.intl.formatMessage({
                      id: "placeholder.select.professions",
                    })}*`}
                    </option>
                    {professions &&
                      professions.map((item, i) => {
                        return (
                          <option key={"option" + i} value={item.id}>
                            {item.label}
                          </option>
                        );
                      })}
                  </select>
                  {errors.profession && errors.profession.message && (
                    <p className="textInput__error">
                      {errors.profession.message}
                    </p>
                  )}
                </div>
                <div className="textInput__wrapper--spacer"></div>
              </div>

              <p
                style={{marginBottom: '16px'}}
                dangerouslySetInnerHTML={{
                  __html: props.intl.formatMessage({
                    id: "newsletter.form.read",
                  }),
                }}
              ></p>

              <div className="textInput__wrapper textInput__wrapper--policy">
                <div className="textInput__policyBlock">
                  <input
                    type="checkbox"
                    className="textInput__checkboxPolicy"
                    name="policy1"
                    id="policy1"
                    value="on"
                    ref={register({
                      required: props.intl.formatMessage({
                        id: "validation.required",
                      }),
                    })}
                  />
                  <div>
                    <label
                      htmlFor="policy1"
                      className="textInput__policy"
                      dangerouslySetInnerHTML={{
                        __html: props.intl.formatMessage({
                          id: "newsletter.form.policy1",
                        }),
                      }}
                    ></label>
                  </div>
                </div>
                {errors.policy1 && (
                  <p className=" textInput__error">
                    <FormattedMessage id={"validation.required"} />
                  </p>
                )}
              </div>

              <div className="textInput__wrapper textInput__wrapper--policy">
                <div className="textInput__policyBlock">
                  <input
                    type="checkbox"
                    className="textInput__checkboxPolicy"
                    name="policy2"
                    id="policy2"
                    ref={register({
                      
                    })}
                  />
                  <div>
                    <label
                      htmlFor="policy2"
                      className="textInput__policy"
                      dangerouslySetInnerHTML={{
                        __html: props.intl.formatMessage({
                          id: "newsletter.form.policy2",
                        }),
                      }}
                    ></label>
                  </div>
                </div>
              </div>

              <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />

              <button type="submit" className="AuthenticationModal__button">
                <FormattedMessage id={"newsletter.form.button"} />
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default injectIntl(FormNewsletter);
