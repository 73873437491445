import React, {useState, useEffect, useContext} from 'react';
import {ConfigContext} from './ConfigContextProvider';
export const LocaleContext = React.createContext();


const search = window.location.search;
const params = new URLSearchParams(search);
const langQuery = params.get('lang');
let langSite= String(window.lang+"")

const LocaleContextProvider = props => {

    langSite = langSite ==="00" ? "OO" : langSite ;
    if(langSite=="undefined" || langSite=="") langSite=null;
    const [locale, setLocale] = useState( langQuery || langSite || "it"  );

    const [messages, setMessages] = useState(null);
    const {TRANSLATIONS_URL} = useContext(ConfigContext);

    const store = {
      locale: locale,
      messages: messages
    }

    useEffect(() => {
      if(!messages ) {
        fetch(TRANSLATIONS_URL+locale+".json")
        .then(res => res.json())
        .then(
          (result) => {
            setMessages(result)
          },
          (error) => {
          }
        )
      }
    }, [locale]);

    return (
        <LocaleContext.Provider value={store}>
            {messages && props.children}
        </LocaleContext.Provider>
    );


};

export default LocaleContextProvider