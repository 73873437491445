import React, {useState, useEffect, createContext} from 'react';
export const ConfigContext = createContext();

const ConfigContextProvider = props => {
    const [config, setConfig] = useState(null);
    useEffect(() => {
      if(!config ) {
        fetch(process.env.PUBLIC_URL+"/config.json")
        .then(res => res.json())
        .then(
          (result) => {
              if (window.location.href.match(/flou/)) {
                  setConfig(result.PROD)
              } else {
                  setConfig(result.DEV)
              }
          },
          (error) => {
          }
        )
      }
    }, []);
    return (
        <ConfigContext.Provider value={config}>
            {config && props.children}
        </ConfigContext.Provider>
    );
};

export default ConfigContextProvider