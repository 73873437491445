import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { ConfigContext } from "../ConfigContextProvider";
import { LocaleContext } from "../LocaleContextProvider";
import { FormattedMessage, injectIntl } from "react-intl";
import { useForm } from "react-hook-form";
import Modal from "react-modal";

const FormLogin = (props) => {
  const { closeModal, appId } = props;
  const { LOGIN_URL } = useContext(ConfigContext);

  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const { locale } = useContext(LocaleContext);

  //register
  const [mode, setMode] = useState("LOGIN");

  const [countries, setCountries] = useState();

  const [status, setStatus] = useState();
  const [statusChangePwd, setStatusChangePwd] = useState();

  const translateText = function (tstr) {
    return props.intl.formatMessage({
      id: tstr,
    });
    //document.form.entity.value = bstr;
  };

  const genericError = props.intl.formatMessage({
    id: "generic.form.error",
  });

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "all",
    reValidateMode: "all",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const passwordWatch = useRef({});
  passwordWatch.current = watch("pwd", "");

  const onLogin = useCallback((data) => {
    setErrorMessage();
    setSuccessMessage();
    setStatus();
    let formData = new FormData();
    let headers = new Headers();
    for (var i in data) {
      formData.append(i, data[i]);
      //formData.append(i, convertToEntities(data[i]));
    }
    //headers.append('Content-Type', 'application/json');
    //headers.set('Authorization', 'Basic ' + btoa(data['usr'] + ":" + data['pwd']));
    //headers.append('Authorization', 'Basic ' + base64.encode(usr + ":" + pwd));

    fetch(LOGIN_URL, {
      method: "POST",
      //body: JSON.stringify(data),
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.settings.login == "1") {
          localStorage.setItem("ssid", responseJson.settings.ssid);
          const event = new CustomEvent("updateUserOption", {
            detail: responseJson.settings,
          });
          document.dispatchEvent(event);

          let objToSend = {
            event: "login",
            logged_in: true,
            site_lang: locale,
            user_id: responseJson.settings.uidr,
            user_data: {
              email: responseJson.settings.login_email,
              address: {
                first_name: responseJson.settings.login_first_name,
                last_name: responseJson.settings.login_last_name,
              },
            },
          };

          window.sendToGTM && window.sendToGTM(objToSend);
          window.gtmInitEvent && window.gtmInitEvent(objToSend);
          

          closeModal();
        } else {
          setErrorMessage(translateText(responseJson.settings.loginmsg));
        }
      })
      .catch((error) => {
        setErrorMessage(genericError);
      });
  });

  const appIdLabel = (appId && appId.length) > 0 ? appId + "." : "";

  return (
    <>
      <div className="AuthenticationModal__title">
        <h1>
          <FormattedMessage id={appIdLabel + "login.form.title"} />
        </h1>
        <p>
          <FormattedMessage id={appIdLabel + "login.form.description"} />
        </p>
      </div>

      {status === "SUCCESS" ? (
        <>
          <div className="AuthenticationModal__message">
            <p
              dangerouslySetInnerHTML={{
                __html: props.intl.formatMessage({
                  id: "login.form.success",
                }),
              }}
            />
          </div>
          <button className="AuthenticationModal__button" onClick={closeModal}>
            Chiudi
          </button>
        </>
      ) : (
        <>
          {errorMessage && <p className="textInput__error">{errorMessage}</p>}
          <form onSubmit={handleSubmit(onLogin)}>
            <input
              type="hidden"
              name="lang"
              defaultValue={locale}
              ref={register({ required: true })}
            />
            <input
              type="hidden"
              name="appId"
              defaultValue={appId}
              ref={register()}
            />
            <div className="textInput__wrapper">
              <input
                className="textInput__input"
                name="usr"
                placeholder={`${props.intl.formatMessage({
                  id: "placeholder.email",
                })}*`}
                ref={register({
                  required: props.intl.formatMessage({
                    id: "validation.required",
                  }),
                })}
              />
              {errors.usr && errors.usr.message && (
                <p className="textInput__error">{errors.usr.message}</p>
              )}
            </div>

            <div className="textInput__wrapper">
              <input
                className="textInput__input"
                name="pwd"
                type="password"
                placeholder={`${props.intl.formatMessage({
                  id: "placeholder.password",
                })}*`}
                ref={register({
                  required: props.intl.formatMessage({
                    id: "validation.required",
                  }),
                })}
              />
              {errors.pwd && (
                <p className="textInput__error">
                  <FormattedMessage id={"validation.required"} />
                </p>
              )}
            </div>

            <p className="textInput__passwordForgot">
              <a
                className="AuthenticationModal__link"
                onClick={() => props.onSwitchMode("PASSWORD")}
              >
                <FormattedMessage id={"login.form.password.forgot"} />
              </a>
            </p>

            <p className="AuthenticationModal__policy">
              <span
                dangerouslySetInnerHTML={{
                  __html: props.intl.formatMessage({
                    id: "login.policy.description",
                  }),
                }}
              />
            </p>

            <button type="submit" className="AuthenticationModal__button">
              <FormattedMessage id={"login.form.button"} />
            </button>
          </form>

          <p className="AuthenticationModal__note">
            <FormattedMessage id={"login.form.noaccount"} />{" "}
            <a
              className="AuthenticationModal__link"
              onClick={() => props.onSwitchMode("REGISTER")}
            >
              <FormattedMessage id={"login.form.register.link.label"} />
            </a>
          </p>
        </>
      )}
    </>
  );
};

export default injectIntl(FormLogin);
