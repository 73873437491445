import React, { useState, useEffect, useContext, useCallback } from "react";
import "./App.scss";

import { createIntl, createIntlCache } from "react-intl";
import { IntlProvider, FormattedMessage } from "react-intl";

import { ConfigContext } from "./components/ConfigContextProvider";
import { LocaleContext } from "./components/LocaleContextProvider";
import { AuthenticationContext } from "./components/AuthenticationContextProvider";
import ModalLogin from "./components/ModalLogin/ModalLogin";

//import 'bootstrap/dist/css/bootstrap.min.css';
//LANGUAGE

function App() {
  const { locale } = useContext(LocaleContext);
  const { messages } = useContext(LocaleContext);
  const userOptions = useContext(AuthenticationContext);
  const [loginIsOpen, setLoginIsOpen] = useState(false);
  const [appId, setAppId] = useState("");
  const [email, setEmail] = useState("");
  const [fMode, setfMode] = useState("NEWSLETTER");

  const openModal = useCallback(() => {
    setLoginIsOpen(true);
  });

  useEffect(() => {
    if (loginIsOpen) document.body.style.overflow = "hidden";
    if (!loginIsOpen) document.body.style.overflow = "unset";
  }, [loginIsOpen]);

  const closeModalLogin = useCallback(() => {
    setLoginIsOpen(false);
  });

  document.addEventListener(
    "login",
    function (e) {
      if (e.detail && e.detail.APPID) {
        setAppId(e.detail.APPID);
      }
      if (e.detail && e.detail.fMode) {
        setfMode(e.detail.fMode);
      }
      setLoginIsOpen(true);
    },
    false
  );

  document.addEventListener(
    "newsletter",
    function (e) {
      if (e.detail && e.detail.APPID) {
        setAppId(e.detail.APPID);
      }
      if (e.detail && e.detail.email) {
        setEmail(e.detail.email);
      }
      if (e.detail && e.detail.fMode) {
        setfMode(e.detail.fMode);
      }

      setLoginIsOpen(true);
    },
    false
  );

  return (
    <div>
      {messages && (
        <IntlProvider locale={locale} messages={messages}>
          <ModalLogin
            appId={appId}
            classNmae={fMode}
            closeTimeoutMS={400}
            modalIsOpen={loginIsOpen}
            closeModal={closeModalLogin}
            email={email}
            fMode={fMode}
          />
        </IntlProvider>
      )}
    </div>
  );
}

export default App;
