import React, {
  useState,
  useCallback,
  useEffect
} from "react";
import {  injectIntl } from "react-intl";
import Modal from "react-modal";
import FormLogin from "./FormLogin";
import FormSignIn from "./FormSignIn";
import FormRequestPassword from "./FormRequestPassword";
import FormNewsletter from "./FormNewsletter";


const ModalLogin = (props) => {
  const { modalIsOpen, closeModal, appId, email, fMode } = props;

  //register
  const [mode, setMode] = useState(fMode);
  const onSwitchMode = useCallback((mode) => {
    setMode(mode);
  }, [fMode]);

  useEffect(() => {
    setMode(fMode);
  }, [fMode]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={`AuthenticationModal AuthenticationModal--${String(mode).toLowerCase()}`}
        contentLabel="Authentication Modal"
        ariaHideApp={false}
        bodyOpenClassName = {mode === "NEWSLETTER" ? "Modal__newsletter" : ""}
      >
        <div className={`AuthenticationModal__wrapper`}>
          <button
            className="AuthenticationModal__btnClose"
            onClick={() => closeModal()}
          >
            ✕
          </button>

          {/* LOGIN CHANGE PASSWORD */}
          {mode === "PASSWORD" && (
            <FormRequestPassword onSwitchMode={onSwitchMode} closeModal={closeModal} appId={appId}></FormRequestPassword>
          )}
          {/* LOGIN MODULE */}
          {mode === "LOGIN" && (
            <FormLogin onSwitchMode={onSwitchMode} closeModal={closeModal} appId={appId}></FormLogin>
          )}
          {/* REGISTER MODULE */}
          {mode === "REGISTER" && (
            <FormSignIn onSwitchMode={onSwitchMode} closeModal={closeModal} appId={appId}></FormSignIn>
          )}
          {/* NEWSLETTER MODULE  6LfzaFwiAAAAAEBoRuBECHI4C4Am5tubju2XD4KY */}
          {/* test 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI */}
          {mode === "NEWSLETTER" && (
              <FormNewsletter onSwitchMode={onSwitchMode} closeModal={closeModal} appId={appId} email={email}></FormNewsletter>
          )}

        </div>
      </Modal>
    </>
  );
};

export default injectIntl(ModalLogin);
