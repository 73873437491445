import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { ConfigContext } from "../ConfigContextProvider";
import { LocaleContext } from "../LocaleContextProvider";
import { FormattedMessage, injectIntl } from "react-intl";
import { useForm } from "react-hook-form";
import Modal from "react-modal";

const FormRequestPassword = (props) => {
  const { closeModal, appId } = props;
  const { REGISTER_URL } = useContext(ConfigContext);
  const { COUNTRY_URL } = useContext(ConfigContext);
  const { REQUEST_PASSWORD_URL } = useContext(ConfigContext);

  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const { locale } = useContext(LocaleContext);

  //register
  const [mode, setMode] = useState("LOGIN");

  const [countries, setCountries] = useState();

  const [status, setStatus] = useState();
  const [statusChangePwd, setStatusChangePwd] = useState();

  const translateText = function (tstr) {
    return props.intl.formatMessage({
      id: tstr,
    });
    //document.form.entity.value = bstr;
  };

  const genericError = props.intl.formatMessage({
    id: "generic.form.error",
  });

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "all",
    reValidateMode: "all",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const passwordWatch = useRef({});
  passwordWatch.current = watch("pwd", "");


  const onRequestPassword = useCallback((data) => {
    setErrorMessage();
    setSuccessMessage();
    setStatusChangePwd();
    // let data = {
    //     usr: username,
    //     pwd: password
    //   };
    let formData = new FormData();
    for (var i in data) {
      formData.append(i, data[i]);
    }
    fetch(REQUEST_PASSWORD_URL, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.settings.success == "1") {
          setStatusChangePwd("SUCCESS");
          //general dispatch
          //closeModal();
          setSuccessMessage(translateText(responseJson.settings.message));
        } else {
          setErrorMessage(translateText(responseJson.settings.message));
        }
      })
      .catch((error) => {
        setErrorMessage(genericError);
      });
  });


  const appIdLabel = (appId && appId.length) > 0 ? appId + "." : "";

  return (
    <>
      <div className="AuthenticationModal__title">
        <h1>
          <FormattedMessage id={"requestpassword.form.title"} />
        </h1>
        <p>
          <FormattedMessage id={"requestpassword.form.description"} />
        </p>
      </div>

      {statusChangePwd === "SUCCESS" ? (
        <>
          <div className="AuthenticationModal__message">
            <p
              dangerouslySetInnerHTML={{
                __html:
                  successMessage ||
                  props.intl.formatMessage({
                    id: "requestpassword.form.success",
                  }),
              }}
            />
          </div>
          <button
            className="AuthenticationModal__button"
            onClick={() => {
              setStatusChangePwd();
              setMode("LOGIN");
              closeModal();
            }}
          >
            Chiudi
          </button>
        </>
      ) : (
        <>
          {errorMessage && <p className="textInput__error">{errorMessage}</p>}

          <form onSubmit={handleSubmit(onRequestPassword)}>
            <input
              type="hidden"
              name="lang"
              defaultValue={locale}
              ref={register({ required: true })}
            />
            <input
              type="hidden"
              name="appId"
              defaultValue={appId}
              ref={register()}
            />
            <div className="textInput__wrapper">
              <input
                className="textInput__input"
                name="usr"
                placeholder={`${props.intl.formatMessage({
                  id: "placeholder.email",
                })}*`}
                ref={register({
                  required: props.intl.formatMessage({
                    id: "validation.required",
                  }),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: props.intl.formatMessage({
                      id: "validation.email",
                    }),
                  },
                })}
              />
              {errors.usr && errors.usr.message && (
                <p className="textInput__error">{errors.usr.message}</p>
              )}
            </div>

            <button type="submit" className="AuthenticationModal__button">
              <FormattedMessage id={"requestpassword.form.button"} />
            </button>
          </form>

          <p className="AuthenticationModal__note">
            <FormattedMessage id={"requestpassword.form.backlogin"} />{" "}
            <a
              className="AuthenticationModal__link"
              onClick={() => props.onSwitchMode("LOGIN")}
            >
              <FormattedMessage id={"login.register.link"} />
            </a>
          </p>
        </>
      )}
    </>
  );
};

export default injectIntl(FormRequestPassword);
