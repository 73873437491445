import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import ConfigContextProvider from './components/ConfigContextProvider';
import LocaleContextProvider from './components/LocaleContextProvider';
import AuthenticationContextProvider from './components/AuthenticationContextProvider';
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";


import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <ConfigContextProvider>
      <LocaleContextProvider>
        <AuthenticationContextProvider>
          <GoogleReCaptchaProvider reCaptchaKey="6LfzaFwiAAAAAEBoRuBECHI4C4Am5tubju2XD4KY">
          <App />
          </GoogleReCaptchaProvider>
        </AuthenticationContextProvider>
      </LocaleContextProvider>
    </ConfigContextProvider>
  </React.StrictMode>,
  document.getElementById('rootAuthentication')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


