import React, {useState, useEffect, useContext, useCallback} from 'react';
import {ConfigContext} from './ConfigContextProvider';


export const AuthenticationContext = React.createContext();

const AuthenticationContextProvider = props => {
    const [userOptions, setUserOptions] = useState(null);
    const {USEROPTIONS_URL} = useContext(ConfigContext);

    //check option
    const onCheckUseOption= useCallback(() => {
      let data = {
          ssid: localStorage.getItem('ssid')
      };
      let formData = new FormData();
      for(var i in data){
        formData.append(i,data[i]);
      }
        fetch(USEROPTIONS_URL, {
          method: 'POST',
          body: formData 
        })
        .then(response => response.json())
        .then((responseJson) => {
            //localStorage.setItem('ssid', responseJson.settings.ssid);
            //general dispatch
            setUserOptions(responseJson.settings)
            const event = new CustomEvent("updateUserOption", {'detail': responseJson.settings});
            document.dispatchEvent(event);
        })
        .catch(error => console.log(error));
    });

    

            //setUserOptions(e.detail)
        //setLoginIsOpen(true);

    useEffect(() => {
      document.removeEventListener('checkUserOption',  onCheckUseOption);
      document.addEventListener('checkUserOption',  onCheckUseOption, false);
      //onCheckUseOption();
        // fetch(USEROPTIONS_URL)
        // .then(res => res.json())
        // .then(
        //   (result) => {
        //     console.log(result.settings);
        //     setUserOptions(result.settings)
        //   },
        //   (error) => {
        //   }
        // )
    }, []);

    return (
        <AuthenticationContext.Provider value={userOptions}>
            {props.children}
        </AuthenticationContext.Provider>
    );


};

export default AuthenticationContextProvider