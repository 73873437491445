import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import { ConfigContext } from "../ConfigContextProvider";
import { LocaleContext } from "../LocaleContextProvider";
import { FormattedMessage, injectIntl } from "react-intl";
import { useForm } from "react-hook-form";
import Modal from "react-modal";

const FormSignIn = (props) => {
  const { closeModal, appId } = props;
  const { REGISTER_URL, COUNTRY_URL, SECTOR_URL, ROLE_URL } =
    useContext(ConfigContext);

  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const { locale } = useContext(LocaleContext);

  //register
  const [mode, setMode] = useState("LOGIN");

  const [countries, setCountries] = useState();
  const [roles, setRoles] = useState();
  const [sectors, setSectors] = useState();

  const [status, setStatus] = useState();
  const [statusChangePwd, setStatusChangePwd] = useState();

  const translateText = function (tstr) {
    return props.intl.formatMessage({
      id: tstr,
    });
    //document.form.entity.value = bstr;
  };

  const genericError = props.intl.formatMessage({
    id: "generic.form.error",
  });

  const { register, handleSubmit, watch, errors } = useForm({
    mode: "all",
    reValidateMode: "all",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const passwordWatch = useRef({});
  passwordWatch.current = watch("pwd", "");

  const convertToEntities = function (tstr) {
    //var tstr = document.form.unicode.value;
    var bstr = "";
    for (let i = 0; i < tstr.length; i++) {
      if (tstr.charCodeAt(i) > 127) {
        bstr += "&#" + tstr.charCodeAt(i) + ";";
      } else {
        bstr += tstr.charAt(i);
      }
    }
    return bstr;
    //document.form.entity.value = bstr;
  };

  const onSignIn = useCallback((data) => {
    //  let data = {
    //       name: username,
    //       password: password,
    //       email: email,
    //       nazione: nazione
    //     };
    setStatus();
    setSuccessMessage();
    let formData = new FormData();
    for (var i in data) {
      if (i == "usr") {
        formData.append(i, convertToEntities(data[i]));
      } else {
        formData.append(i, data[i]);
      }
    }
    fetch(REGISTER_URL, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // if(responseJson.settings.login=="1") {
        //   setStatus("SUCCESS")
        // }
        localStorage.setItem("ssid", responseJson.settings.ssid);
        const event = new CustomEvent("updateUserOption", {
          detail: responseJson.settings,
        });
        document.dispatchEvent(event);
        if (responseJson.settings.login == "1") {

          let objToSend= {
            event: "sign_up",
            category: appId,
            logged_in: true,
            site_lang: locale,
            user_id: responseJson.settings.uidr,
            user_data: {
                phone_number: formData.get('phone'),
                email: formData.get('email'),
                address: {
                    first_name: formData.get('name'),
                    last_name: formData.get('surname'),
                    city: formData.get('city'),
                    country: formData.get('country')
                }
            },
            user_properties: {
                company: formData.get('company'),
                role: formData.get('role') 
            }
        };

        let selectElement = document.getElementById('role');
        let selectedOption = selectElement && selectElement.options[selectElement.selectedIndex];
        let roleGTA = selectedOption && selectedOption.getAttribute('data-role-gta');

        if(roleGTA) {
          objToSend.user_properties.role= roleGTA;
        }

        window.sendToGTM && window.sendToGTM(objToSend)
        setStatus("SUCCESS");
        
        } else {
          setErrorMessage(translateText(responseJson.settings.loginmsg));
        }
      })
      .catch((error) => {
        setErrorMessage(genericError);
      });
  });

  useEffect(() => {
    fetch(COUNTRY_URL)
      .then((res) => res.json())
      .then(
        (result) => {
          setCountries(result.countries);
        },
        (error) => {}
      );

    fetch(ROLE_URL + "_" + locale + ".json")
      .then((res) => res.json())
      .then(
        (result) => {
          setRoles(result.ruoli);
        },
        (error) => {}
      );

    fetch(SECTOR_URL + "_" + locale + ".json")
      .then((res) => res.json())
      .then(
        (result) => {
          setSectors(result.settori);
        },
        (error) => {}
      );
  }, []);

  const appIdLabel = (appId && appId.length) > 0 ? appId + "." : "";

  return (
    <>
      {status === "SUCCESS" ? (
        <>
          <div className="AuthenticationModal__title">
            <div className="AuthenticationModal__title">
              <h1>
                <FormattedMessage id={"register.form.title.success"} />
              </h1>
            </div>
          </div>
          <div className="AuthenticationModal__message">
            <p
              dangerouslySetInnerHTML={{
                __html: props.intl.formatMessage({
                  id: "register.form.success",
                }),
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="AuthenticationModal__title">
            <div className="AuthenticationModal__title">
              <h1>
                <FormattedMessage id={appIdLabel + "register.form.title"} />
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: props.intl.formatMessage({
                    id: appIdLabel + "register.form.description",
                  }),
                }}
              />
            </div>
          </div>
          {errorMessage && <p className="textInput__error">{errorMessage}</p>}
          <form onSubmit={handleSubmit(onSignIn)}>
            <input
              type="hidden"
              name="lang"
              defaultValue={locale}
              ref={register({ required: true })}
            />
            <input
              type="hidden"
              name="appId"
              defaultValue={appId}
              ref={register()}
            />

            <div className="AuthenticationModal__rowForm">
              {/* nome */}
              <div className="textInput__wrapper">
                <input
                  className="textInput__input"
                  name="name"
                  placeholder={`${props.intl.formatMessage({
                    id: "placeholder.name",
                  })}*`}
                  ref={register({
                    required: props.intl.formatMessage({
                      id: "validation.required",
                    }),
                  })}
                />
                {errors.name && errors.name.message && (
                  <p className="textInput__error">{errors.name.message}</p>
                )}
              </div>

              {/* cognome */}
              <div className="textInput__wrapper">
                <input
                  className="textInput__input"
                  name="surname"
                  placeholder={`${props.intl.formatMessage({
                    id: "placeholder.surname",
                  })}*`}
                  ref={register({
                    required: props.intl.formatMessage({
                      id: "validation.required",
                    }),
                  })}
                />
                {errors.surname && errors.surname.message && (
                  <p className="textInput__error">{errors.surname.message}</p>
                )}
              </div>
            </div>
            <div className="AuthenticationModal__rowForm">
              {/* email */}
              <div className="textInput__wrapper">
                <input
                  className="textInput__input"
                  name="email"
                  placeholder={`${props.intl.formatMessage({
                    id: "placeholder.email",
                  })}*`}
                  ref={register({
                    required: props.intl.formatMessage({
                      id: "validation.required",
                    }),
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: props.intl.formatMessage({
                        id: "validation.email",
                      }),
                    },
                  })}
                />
                {errors.email && errors.email.message && (
                  <p className="textInput__error">{errors.email.message}</p>
                )}
              </div>

              {/* phone */}
              <div className="textInput__wrapper">
                <input
                  className="textInput__input"
                  name="phone"
                  placeholder={`${props.intl.formatMessage({
                    id: "placeholder.phone",
                  })}*`}
                  ref={register({
                    required: props.intl.formatMessage({
                      id: "validation.required",
                    }),
                  })}
                />
                {errors.phone && errors.phone.message && (
                  <p className="textInput__error">{errors.phone.message}</p>
                )}
              </div>
            </div>
            <div className="AuthenticationModal__rowForm">
              {/* country */}
              <div className="textInput__wrapper">
                <select
                  name="country"
                  defaultValue=""
                  className="textInput__input"
                  ref={register({
                    required: props.intl.formatMessage({
                      id: "validation.required",
                    }),
                    pattern: {
                      value: /^[a-zA-Z]{2,}$/,
                      message: props.intl.formatMessage({
                        id: "validation.required",
                      }),
                    },
                  })}
                >
                  <option key={"option"} value={""} disabled>
                  {`${props.intl.formatMessage({
                      id: "placeholder.select.country",
                    })}*`}
                  </option>
                  {countries &&
                    countries.map((item, i) => {
                      return (
                        <option key={"option" + i} value={item.id}>
                          {item.label}
                        </option>
                      );
                    })}
                </select>
                {errors.country && errors.country.message && (
                  <p className="textInput__error">{errors.country.message}</p>
                )}
              </div>

              {/* city */}
              <div className="textInput__wrapper">
                <input
                  className="textInput__input"
                  name="city"
                  placeholder={`${props.intl.formatMessage({
                    id: "placeholder.city",
                  })}*`}
                  ref={register({
                    required: props.intl.formatMessage({
                      id: "validation.required",
                    }),
                  })}
                />
                {errors.city && errors.city.message && (
                  <p className="textInput__error">{errors.city.message}</p>
                )}
              </div>
            </div>

            <div className="AuthenticationModal__rowForm">
              {/* company */}
              <div className="textInput__wrapper">
                <input
                  className="textInput__input"
                  name="company"
                  placeholder={`${props.intl.formatMessage({
                    id: "placeholder.company",
                  })}*`}
                  ref={register({
                    required: props.intl.formatMessage({
                      id: "validation.required",
                    }),
                  })}
                />
                {errors.company && errors.company.message && (
                  <p className="textInput__error">{errors.company.message}</p>
                )}
              </div>
              {/* role */}
              <div className="textInput__wrapper">
                <select
                  name="role"
                  id="role"

                  defaultValue=""
                  className="textInput__input"
                  ref={register({})}
                  
                >
                  <option key={"option"} value={""} disabled>
                    {props.intl.formatMessage({
                      id: "placeholder.select.role",
                    })}
                  </option>
                  {roles &&
                    roles.map((item, i) => {
                      return (
                        <option key={"option" + i} value={item.id} data-role-gta={item.label_gta}>
                          {item.label}
                        </option>
                      );
                    })}
                </select>
                {errors.role && errors.role.message && (
                  <p className="textInput__error">{errors.role.message}</p>
                )}
              </div>
            </div>
            <div className="AuthenticationModal__rowForm">
              {/* sector */}
              <div className="textInput__wrapper">
                <select
                  name="sector"
                  defaultValue=""
                  className="textInput__input"
                  ref={register({})}
                  
                >
                  <option key={"option"} value={""} disabled>
                    {props.intl.formatMessage({
                      id: "placeholder.select.sector",
                    })}
                  </option>
                  {sectors &&
                    sectors.map((item, i) => {
                      return (
                        <option key={"option" + i} value={item.id}>
                          {item.label}
                        </option>
                      );
                    })}
                </select>
                {errors.sector && errors.sector.message && (
                  <p className="textInput__error">{errors.sector.message}</p>
                )}
              </div>
              <div className="textInput__wrapper--spacer"></div>
            </div>

            <div className="AuthenticationModal__rowForm--sep"></div>

            <div className="AuthenticationModal__rowForm">
              {/* password */}
              <div className="textInput__wrapper">
                <input
                  className="textInput__input"
                  name="pwd"
                  type="password"
                  placeholder={`${props.intl.formatMessage({
                    id: "placeholder.password",
                  })}*`}
                  ref={register({
                    required: props.intl.formatMessage({
                      id: "validation.required",
                    }),
                    minLength: {
                      value: 8,
                      message: props.intl.formatMessage({
                        id: "validation.atleast",
                      }),
                    },
                    pattern: {
                      value:
                        /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]+$/,
                      message: props.intl.formatMessage({
                        id: "validation.chars",
                      }),
                    },
                  })}
                />
                {errors.pwd && errors.pwd.message && (
                  <p className="textInput__error">{errors.pwd.message}</p>
                )}
              </div>

              {/* confirm password */}
              <div className="textInput__wrapper">
                <input
                  className="textInput__input"
                  name="confirmPwd"
                  type="password"
                  placeholder={`${props.intl.formatMessage({
                    id: "placeholder.password.confirm",
                  })}*`}
                  ref={register({
                    required: props.intl.formatMessage({
                      id: "validation.required",
                    }),
                    validate: (value) =>
                      value === passwordWatch.current ||
                    props.intl.formatMessage({
                      id: "register.form.password.match",
                    })
                  })}
                />
                {errors.confirmPwd && (
                  <p className="textInput__error">
                    {errors.confirmPwd.message}
                  </p>
                )}
              </div>
            </div>

            <p className="AuthenticationModal__policy">
              <span
                dangerouslySetInnerHTML={{
                  __html: props.intl.formatMessage({
                    id: "register.policy.description",
                  }),
                }}
              />
            </p>

            <button type="submit" className="AuthenticationModal__button">
              <FormattedMessage id={"register.form.button"} />
            </button>
          </form>

          <p className="AuthenticationModal__note">
            <FormattedMessage id={"register.form.login.description"} />{" "}
            <a
              className="AuthenticationModal__link"
              onClick={() => props.onSwitchMode("LOGIN")}
            >
              <FormattedMessage id={"register.form.login.link"} />
            </a>
          </p>
        </>
      )}
    </>
  );
};

export default injectIntl(FormSignIn);
